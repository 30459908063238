.App {
  text-align: center;
}

* {
  margin: 0px;
  padding: 0px;
  outline: none;
}

body {
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.p-chart canvas {
  height: 250px !important;
  /* width:250px!important; */
}

.logoBg {
  background: #fff;
  padding: 15px;
  border-radius: 15px;
}

.clear {
  clear: both;
}

.updateButton {
  background: #e97877 !important;
  border-radius: 6px !important;
  text-transform: uppercase;
  padding: 10px;
  border: none;
  outline: none !important;
  color: #fff;
  border: 1px solid #e97877 !important;
  margin-left: 15px !important;
}

.filterButton {
  background: #fff;
  border-radius: 6px;
  text-transform: uppercase;
  padding: 10px;
  border: none;
  outline: none;
  color: #333;
  border: 1px solid #ccc;
}

.titleHeading h3 {
  font-weight: normal;
  font-size: 22px;
  margin: 0px !important;
}

.p-datascroller-list {
  list-style: none !important;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  max-width: 297px !important;
  width: 80% !important;
}

.p-datascroller-content {
  background: none !important;
}

.p-datascroller-list li {
  line-height: 35px !important;
  border: none !important;
}

.p-datascroller-list li:first-child .main-list .sub-list::after {
  content: "\e931" !important;
  float: right;
  position: absolute;
  right: 0;
  font-family: "primeicons";
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 22px;
  padding-right: 10px;
  -webkit-font-smoothing: antialiased;
  line-height: 35px !important;
}

.p-datascroller-list li .main-list .sub-list {
  line-height: 35px !important;
}

.p-datascroller-list li:first-child .main-list .sub-list::before {
  content: "\e969" !important;
  float: left;
  font-family: "primeicons";
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 22px;
  padding-right: 10px;
  -webkit-font-smoothing: antialiased;
  line-height: 35px !important;
}

.p-datascroller-list li:nth-child(2) .main-list .sub-list::before {
  /*content: "\e96f"!important;*/
  content: "\e96f" !important;
  float: left;
  font-family: "primeicons";
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 22px;
  padding-right: 10px;
  -webkit-font-smoothing: antialiased;
  line-height: 35px !important;
}

.p-datascroller-list li:nth-child(3) .main-list .sub-list::before {
  /*content: "\e96f"!important;*/
  content: "\e94a" !important;
  float: left;
  font-family: "primeicons";
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 22px;
  padding-right: 10px;
  -webkit-font-smoothing: antialiased;
  line-height: 35px !important;
}

.p-datascroller-list li:nth-child(4) .main-list .sub-list::before {
  /*content: "\e96f"!important;*/
  content: "\e993" !important;
  float: left;
  font-family: "primeicons";
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 22px;
  padding-right: 10px;
  -webkit-font-smoothing: antialiased;
  line-height: 35px !important;
}

.p-datascroller-list li:nth-child(5) .main-list .sub-list::before {
  /*content: "\e96f"!important;*/
  content: "\e936" !important;
  float: left;
  font-family: "primeicons";
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 22px;
  padding-right: 10px;
  -webkit-font-smoothing: antialiased;
  line-height: 35px !important;
}

.content {
  position: absolute;
  right: 0;
}

.clear {
  clear: both !important;
}

/* .plus{
  width:40px;
  height:40px;
  float: right;
  margin-right: 15px;
} */

.plus {
  /* width: 131px;
  height: 35px; */
  float: right;
  margin-right: 20px;
}

.blue-tooltip.p-tooltip .p-tooltip-arrow {
  /* border-top-color: var(--blue-500); */
  border-top-color: rgba(59, 130, 246, 0.5);
}

.blue-tooltip.p-tooltip .p-tooltip-text {
  background-color: var(--blue-500);
}

.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap {
  --tw-text-opacity: 1;
  align-items: center;
  border-left-width: 0px !important;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 0rem !important;
}

.side-navigation-panel-select-wrap i {
  font-size: 12px !important;
}

.side-navigation-panel-select-option {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  border-left-width: 0px !important;
}

.side-navigation-panel-select-inner-option {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  border-left-width: 0px !important;
  font-size: 12px !important;
  /*font-family: "poppins" !important;
  font-weight: bold !important;*/
  background-color: #f8f8f8 !important;
  letter-spacing: 1.2px !important;
  padding: 0rem !important;
}

.side-navigation-panel-select-inner-option-text {
  font-size: 11px !important;
  /*font-family: "poppins" !important;
  font-weight: bold !important;*/
  background-color: #f8f8f8 !important;
  /*letter-spacing: 1.2px !important;*/
}

.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option .side-navigation-panel-select-option-wrap .side-navigation-panel-select-option-text {
  font-size: 14px !important;
  /* font-family: "poppins" !important;
  font-weight: bold !important;
  letter-spacing: 1.2px !important;*/
  color: #333 !important;
}

.side-navigation-panel-select-option-selected {
  background: #f7f6f9 !important;
  font-size: 12px !important;
  border-left: 2px solid #42a5f5 !important;
}

.side-navigation-panel-select-option:hover {
  background: #f7f6f9 !important;
  font-size: 12px !important;
  border-left: 2px solid #42a5f5 !important;
}

.side-navigation-panel-select-option-icon-chevron-down {
  font-size: 10px !important;
}

.side-navigation-panel-select-option-selected::after {
  content: "";
  right: 0;
  position: absolute;
  font-size: 16px;
  /*font-weight: bold;
  font-family: "Poppins";*/
  color: #000;
}

.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option .side-navigation-panel-select-option-wrap .side-navigation-panel-select-option-text {
  font-weight: 900 !important;
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.username1 {
  font-weight: bolder;
  color: #05429f;
  letter-spacing: 1.2px;
  margin-top: -12px;
  font-size: 12px !important;
  line-height: 15px;
  padding-top: 5px;
}

.fileViewImg {
  height: 32px;
  width: 32px;
  object-fit: cover;
}

/*Landing Page*/
.topBar {
  background-color: #fff;
  position: fixed;
  top: 0%;
  height: 30vh;
}

.lgn .p-card-body {
  padding: 0 !important;
}

.lgn .p-card-content {
  padding: 0 !important;
}

.footer {
  background-color: #032970;
  position: fixed;
  bottom: 0%;
}

.wrapper {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(183, 194, 214, 1) 100%);
  background: -webkit-linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(183, 194, 214, 1) 100%);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(183, 194, 214, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#b7c2d6", GradientType=1);
}

.carousel .control-dots .dot {
  transition: opacity .25s ease-in;
  opacity: .3;
  filter: alpha(opacity=30);
  box-shadow: 1px 1px 2px rgb(0 0 0 / 90%);
  background: #032970 !important;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
}

.carousel .control-dots .dot.selected {
  opacity: 1;
  filter: alpha(opacity=100);
  background: #032970 !important;
  padding: 5px !important;
}

.carousel .carousel-status {
  display: none !important;
}

.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background: #FFF !important;
}

.p-inputswitch .p-inputswitch-slider:before {
  background: #01fd1f !important;
  width: 1.25rem;
  height: 1.25rem;
  left: 0.25rem;
  margin-top: -0.625rem;
  border-radius: 50%;
  transition-duration: 0.2s;
}

.p-inputswitch .p-inputswitch-slider {
  background: #FFF !important;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 30px;
}

.en {
  vertical-align: middle;
  margin-top: -20px;
  position: absolute;
  top: 39px;
  right: 85px;
}

.np {
  vertical-align: middle;
  margin-top: -20px;
  position: absolute;
  top: 39px;
  right: 10px;
}


.p-inputswitch {
  position: relative;
  display: inline-block;

}

.p-inputswitchInnerPage {
  position: relative;
  display: inline-block;
  margin-right: 28px !important;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.p-col-1,
.p-col-2,
.p-col-3,
.p-col-4,
.p-col-5,
.p-col-6,
.p-col-7,
.p-col-8,
.p-col-9,
.p-col-10,
.p-col-11,
.p-col-12 {
  flex: 0 0 auto;
  padding: 0.2rem;
}

.p-column-title {
  font-size: 12px;
  /* font-family: 'poppins';*/
}

.card {
  /*font-family: 'poppins'!important;
  font-weight: 700;*/
}

.p-datatable .p-datatable-thead>tr>th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #FFF !important;
  background: #4e70ae !important;
  /* transition: box-shadow 0.2s; */
  color: #FFF;
}

.lgn {
  width: 80%;
  background: none !important;
  box-shadow: none !important;
}

.lgn .p-card-body {
  padding: 1rem;
  width: 88% !important;
}

.loginBtn {
  background-color: #0c6ab3 !important;
  color: #ffffff !important;
  border-color: #065ea7 !important;
}

.signature {
  position: relative;
  top: 0;
}

.HeadingTitle {
  color: #3f66ac !important;
  text-decoration: underline;
  font-size: 18px;
}

.ucTable {
  border-collapse: collapse;
  width: 100%;
}

.ucTable thead tr th {

  padding: 5px 15px;
  font-weight: 600;
  color: #FFF !important;
  background: #4e70ae !important;
  text-align: left;
  font-size: 14px;
}

.ucTable tbody tr td {
  padding: 5px 15px;
  color: #000 !important;
  background: #fff !important;
  font-size: 13px;
  text-align: left;
  /* transition: box-shadow 0.2s; */
}

.tbl-style thead tr th,
.tbl-style tbody tr td {
  padding: 10px;
}

.forgetPassword .p-card-body {

  width: 50% !important;

}


.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option:hover {

  background-color: #ffebee !important;
  font-weight: 700 !important;
}

.officeName h4 {
  text-align: center;
  margin: 0;
  font-size: 1.4vw;
  color: #dd1a00;
}

.officeName h1 {
  text-align: center;
  font-size: 2.2vw;
  margin: 0px;
  font-weight: 600;
  color: #ad0007;
  text-shadow: 1px 1px 2px #8a8a8a;
}

/* .officeName h4 {
  text-align: center;
  margin: 0;
  font-size: 16px;
  color: #dd1a00;
}

.officeName h1 {
  text-align: center;
  font-size: 22px;
  margin: 0px;
  line-height: 32px;
  font-weight: 600;
  color: #ad0007;
  text-shadow: 1px 1px 2px #8a8a8a;
} */

.box-shadow {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.75);
}