/* .main-card { 
    background:#f7f6f9;
    padding: 30px;
    align-content: center;
    margin-top: 25px;
    border-radius: 16px;
     position: fixed;
    left: 0;
    height: 100vh;
    max-height:100%;
    box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 
    0 12px 17px 2px rgba(0, 0, 0, 0.14), 
    0 5px 22px 4px rgba(0, 0, 0, 0.12) !important;
    padding:30px;
} */

.main-card {
  padding: 15px;
  /* align-content: center; */
  margin-top: 15px !important;
  border-top-right-radius: 16px;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 60px;
  left: 0;
  /*min-width:261px;*/
}
.profileBox {
  background: rgb(255, 255, 255);
  padding: 15px;
  border-radius: 8px;
  margin-top: 15px;
  margin-bottom: 15px;
  border:1px solid #eae8ed;
}
.profileBox p {
  text-align: center;
  font-size: 15px;
  margin-bottom: 0px;
  /*font-family: 'poppins';
  font-weight: 700;*/
  line-height: 23px;
}
.logo1{
    height: 65px;
    padding-left: 22px;
    padding-top: 15px;
}

.p30 {
  padding: 30px !important;
}

.circleUser {
  width: 60px;
  height: 60px;
  padding: 15px;
  border-radius: 50%;
  background: #d00000;
  margin: 0% auto;
}
.circleUser .pi-user {
  text-align: center;
  margin: 0% auto;
  padding: 0px;
}
.sidebar-menu {
  padding-left: 0px !important;
}
.sidebar-menu li {
  list-style: none;
  text-align: left;
  width: 100%;
  background-color: #efefef;
  margin-bottom: 2px;
}
.sidebar-menu li a {
  text-decoration: none;
  display: block;
  text-align: left;
  color: #000;
  font-size: 12px;
  padding: 10px;
  border-left: 5px solid rgb(33, 150, 243);
  padding-left: 15px;
}

/* .p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 5px;
    border:none!important;
    color: #495057;
    background:none!important;
    font-weight: 600;
    font-size:12px;
    border-radius: 3px;
    transition: box-shadow 0.2s;
} */

/* .p-highlight{
background: rgb(112 173 255);
color: #fff!important;
}
.p-highlight .p-accordion-header-text{
    color: #fff;
} */

/* .pi-chevron-right:before {
    content: "\e901";
    font-size: 12px;
} */

.copyright {
  position: fixed;
  bottom: 10px;
  font-size: 70%;
  text-align: center;
  left: 14px;
}

/*.datascroller-demo .card .p-component {
  min-height: 270px;
}*/

.datascroller-demo .card{
/* max-height:275px!important; */
overflow-y: auto;
min-height: 51vh;
}

/* .pi-chevron-right:before {
  content: "" !important;
} */

.card::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.card::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

.card::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}


.side-navigation-panel ul li .side-navigation-panel-select-option svg{
  color:#a59f9f!important;
}
.side-navigation-panel ul li .side-navigation-panel-select-option .side-navigation-panel-select-option-icon  {
width:14px!important;
height:14px!important;

}

.side-navigation-panel-select-inner-option-wrap{
  align-items:center;
  display:flex; 
  padding-left:16px!important;
}
.side-navigation-panel-select-inner-option-text {
  padding-top:5px!important;
  padding-bottom:5px!important;
}
.side-navigation-panel-select-inner{
  padding-top:5px!important;
  padding-bottom:5px!important;
  background-color: #f8f8f8!important;
  
}

.signout-btn{
    padding: 5px 15px;
    border-radius: 15px;
    outline: none;
    border: 1px solid #ccc;
    text-align: center;
    font-size: 11px;
    font-family: 'Poppins';
    font-weight: bold;
    color: #000;
    float: none;
    margin:0px auto;
    letter-spacing: 1.2px;
    cursor: pointer;

}
.profileBox button{
  text-align: center;
  float: none;
  margin:0px auto;
}
.signout-btn i{
  font-size: 11px;
  vertical-align: middle;

}

/*#kycForm .p-col-11{
float:right!important;
}

#kycForm .main-body-card{
    width: 91.6667%;
    float: right;
}*/

.side-navigation-panel ul li .side-navigation-panel-select-option {
  padding-top:6px!important;
  padding-bottom:6px!important;
  padding-left:8px!important;
  border-left:2px solid #FFF!important;

}

.p-datatable-scrollable-header-box{
 margin-right:0px!important;
}