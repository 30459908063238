@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap);
body {
  margin: 0;
  /*font-family: 'poppins' !important;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

* {
  padding: 0px;
  margin: 0px;
  outline: none;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Kalimati",
    sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  /* background-color: #f8f8f8; */
  background: #e9e5e5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-class {
  background-color: #ffffff;
}

@media only screen and (min-width: 768px) {
  #kycForm .stick-right {
    padding-right: 0px;
  }

  #kycForm .stick-right input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  #kycForm .stick-left {
    padding-left: 0px;
  }

  #kycForm .stick-left input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

#kycForm .p-card-body {
  padding: 25px !important;
}

#kycForm .p-card-content {
  padding: 0 !important;
}

#kycForm h3 {
  margin: 5px;
  padding-top: 15px;
  padding-bottom: 5px;
  color: #2090e9;
  /*font-family: 'Poppins';
  letter-spacing: 1.2px;*/
  font-weight: bold;

}

#kycForm h2 {
  margin: 5px;
  padding-top: 15px;
  padding-bottom: 5px;
  color: #2090e9;
  font-family: 'Ubuntu';

}

#kycForm h4 {
  margin: 0;
  padding-top: 30px;
  padding-bottom: 0;
  font-size: 20px;
  color: #d00000;
}

#kycForm .p-field {
  margin-bottom: 0;
  padding-bottom: 0;
}

#kycForm .p-field-radiobutton {
  margin-bottom: 0;
  border-radius: 0px;
  display: flex;
  align-items: center;
  padding-left: 20px !important;
  margin: 0px !important;
  margin-top: 0px !important;
  border: 1px solid #CED4DA !important;
  padding: 0.44rem;
}

#kycForm .p-field-radiobutton label {
  margin-bottom: 0;
  border-radius: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1rem;
  color: #737b82;
  background: #ffffff;

}

.p-radiobutton .p-radiobutton-box {
  border: 2px solid #ced4da;
  background: #ffffff;
  width: 17px;
  height: 17px;
  font-size: 0.9rem !important;
  color: #737b82 !important;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

#kycForm .p-inputtext {
  margin-bottom: 0;
  border-radius: 0px;
  padding: 5px;
}

#kycForm .p-dropdown {
  margin-bottom: 0;
  border-radius: 0px;
}


#kycForm .p-field label {
  margin-bottom: 0;
  padding-bottom: 4px;
  padding-left: 0
}

#kycForm .p-button {
  border-radius: 0px !important;
}

.w-100 {
  width: 100% !important;
}

.main-body-card {
  border: 1.5px solid #efefef;
  border-radius: 12px !important;
}

#kycForm {
  margin-left: 17%;
}

.pull-right {
  float: right;
}

.text-center {
  text-align: center;
}

.icon-front {
  z-index: 1;
}

#kycProgressBar {
  background-color: #0075ff;
  -webkit-clip-path: circle(65% at 100% 50%);
          clip-path: circle(65% at 100% 50%);
  height: 100vh;
}

.p-card {
  padding: 0px !important;
  /*margin: 16px !important;*/
}

#kycProgressBar ul {
  width: 100%;
  height: auto;
  padding: 25px;
  margin-top: 33%;
  margin-left: 40%;
}

#kycProgressBar ul li {
  list-style: none;
  float: none;
}

#kycProgressBar ul li a {
  padding: 5px 10px;
  text-decoration: none;
  display: block;
  color: #fff;
}

#kycProgressBar ul li .active {
  font-weight: bold;
  font-size: 1.1em;
}

#kycForm .p-fileupload-buttonbar {
  border-radius: 0px;
  padding: 0px !important
}

#kycForm .p-fileupload-content {
  border-radius: 0px;
}

.keep-left {
  position: absolute;
  margin-left: -40px;
  font-size: 1.5em;
}

.very-bold-icon {
  -webkit-text-stroke: 2px white;
}

.ml-3 {
  margin-left: 3px;
}

.seperator {
  height: 67%;
  width: 1px;
  background: black;
  top: 0;
  bottom: 0;
  position: absolute;
  left: 35%;
  margin-top: 110px;
}

.pt-0 {
  padding-top: 0px !important;
  ;
}

.section-title {
  padding-top: 20px;
  padding-bottom: 5px;
  text-decoration: underline;
  text-decoration-thickness: 3px;
  text-underline-offset: 5px;
  font-weight: bold;
  color: #2090e9;
}

.sub-title {
  padding-top: 10px;
  text-decoration: underline;
  font-weight: bold;
}

p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #2196F3 !important;
  background: #2196F3 !important;
}

.p-radiobutton-box.p-highlight .p-radiobutton-icon {
  -webkit-transform: translateZ(0) scale(0.75, 0.75);
          transform: translateZ(0) scale(0.75, 0.75);
  visibility: visible;
}

.datascroller-demo .card {
  position: relative;
  z-index: 999;
  background: #FFF;
  border-radius: 8px;
  padding: 15px;
  border: 1px solid #eae8ed;

}

.p-inputswitch .p-inputswitch-slider {
  background: #ced4da;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 30px;
  border: 1px solid #CCC !important;

}

.p-inputswitch {
  position: relative;
  display: inline-block;
  /* margin-top: 18px; */
  right: 14px !important;
}

.landing p .p-inputswitch {
  position: relative;
  display: inline-block;
  margin-top: 18px;
  right: 0px !important;
}

.label-class {
  display: flex !important;
  flex: 1 1 !important;
  /* justify-content: center !important; */
}

.main-label {
  font-weight: 600;
  font-size: 15px;
}

.sub-label {
  font-weight: 300;
  font-size: 13px;
}

.tooltip-style {
  font-size: 1em;
  margin-left: 5px;
  color: #98173a;
  cursor: pointer;
}

.idcard-label {
  font-size: 12px;
  font-weight: bold;
  height: 10px;
}

.idcard-value {
  font-size: 10px;
  height: 10px;
}

#kycForm .MuiPaper-root {
  box-shadow: none !important;
}


/* @media print {

  body * {
    visibility: hidden;
  }

  #section-to-print,
  #section-to-print * {
    visibility: visible;
  }

  #section-to-print {
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    width: 920px;
  }

  #ignoreButton {
    display: none;
  }

  #pageBreak {
    page-break-after: always;
  }
} */

.tooltip-icon:hover {
  cursor: pointer
}

.landing-p {
  flex: 1 1;
  color: blue;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
  cursor: pointer;
}

.p-tabview .p-tabview-nav #pr_id_1_header_1 {
  background-color: #ef2222 !important;
}

.p-tabview .p-tabview-nav #pr_id_1_header_2 {
  background-color: #4fa5ef !important;
}

.p-tabview .p-tabview-nav #pr_id_1_header_3 {
  background-color: #fdfda5 !important;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
  color: black !important;
  font-weight: bold !important;
}

.p-tabview .p-tabview-nav li {
  margin-right: 10px !important;
}
.App {
  text-align: center;
}

* {
  margin: 0px;
  padding: 0px;
  outline: none;
}

body {
  overflow-x: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.p-chart canvas {
  height: 250px !important;
  /* width:250px!important; */
}

.logoBg {
  background: #fff;
  padding: 15px;
  border-radius: 15px;
}

.clear {
  clear: both;
}

.updateButton {
  background: #e97877 !important;
  border-radius: 6px !important;
  text-transform: uppercase;
  padding: 10px;
  border: none;
  outline: none !important;
  color: #fff;
  border: 1px solid #e97877 !important;
  margin-left: 15px !important;
}

.filterButton {
  background: #fff;
  border-radius: 6px;
  text-transform: uppercase;
  padding: 10px;
  border: none;
  outline: none;
  color: #333;
  border: 1px solid #ccc;
}

.titleHeading h3 {
  font-weight: normal;
  font-size: 22px;
  margin: 0px !important;
}

.p-datascroller-list {
  list-style: none !important;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  position: absolute;
  max-width: 297px !important;
  width: 80% !important;
}

.p-datascroller-content {
  background: none !important;
}

.p-datascroller-list li {
  line-height: 35px !important;
  border: none !important;
}

.p-datascroller-list li:first-child .main-list .sub-list::after {
  content: "\e931" !important;
  float: right;
  position: absolute;
  right: 0;
  font-family: "primeicons";
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 22px;
  padding-right: 10px;
  -webkit-font-smoothing: antialiased;
  line-height: 35px !important;
}

.p-datascroller-list li .main-list .sub-list {
  line-height: 35px !important;
}

.p-datascroller-list li:first-child .main-list .sub-list::before {
  content: "\e969" !important;
  float: left;
  font-family: "primeicons";
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 22px;
  padding-right: 10px;
  -webkit-font-smoothing: antialiased;
  line-height: 35px !important;
}

.p-datascroller-list li:nth-child(2) .main-list .sub-list::before {
  /*content: "\e96f"!important;*/
  content: "\e96f" !important;
  float: left;
  font-family: "primeicons";
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 22px;
  padding-right: 10px;
  -webkit-font-smoothing: antialiased;
  line-height: 35px !important;
}

.p-datascroller-list li:nth-child(3) .main-list .sub-list::before {
  /*content: "\e96f"!important;*/
  content: "\e94a" !important;
  float: left;
  font-family: "primeicons";
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 22px;
  padding-right: 10px;
  -webkit-font-smoothing: antialiased;
  line-height: 35px !important;
}

.p-datascroller-list li:nth-child(4) .main-list .sub-list::before {
  /*content: "\e96f"!important;*/
  content: "\e993" !important;
  float: left;
  font-family: "primeicons";
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 22px;
  padding-right: 10px;
  -webkit-font-smoothing: antialiased;
  line-height: 35px !important;
}

.p-datascroller-list li:nth-child(5) .main-list .sub-list::before {
  /*content: "\e96f"!important;*/
  content: "\e936" !important;
  float: left;
  font-family: "primeicons";
  font-style: normal;
  font-weight: normal;
  -webkit-font-feature-settings: normal;
          font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 22px;
  padding-right: 10px;
  -webkit-font-smoothing: antialiased;
  line-height: 35px !important;
}

.content {
  position: absolute;
  right: 0;
}

.clear {
  clear: both !important;
}

/* .plus{
  width:40px;
  height:40px;
  float: right;
  margin-right: 15px;
} */

.plus {
  /* width: 131px;
  height: 35px; */
  float: right;
  margin-right: 20px;
}

.blue-tooltip.p-tooltip .p-tooltip-arrow {
  /* border-top-color: var(--blue-500); */
  border-top-color: rgba(59, 130, 246, 0.5);
}

.blue-tooltip.p-tooltip .p-tooltip-text {
  background-color: var(--blue-500);
}

.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap {
  --tw-text-opacity: 1;
  align-items: center;
  border-left-width: 0px !important;
  color: rgba(55, 65, 81, var(--tw-text-opacity));
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 0rem !important;
}

.side-navigation-panel-select-wrap i {
  font-size: 12px !important;
}

.side-navigation-panel-select-option {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  border-left-width: 0px !important;
}

.side-navigation-panel-select-inner-option {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
  border-left-width: 0px !important;
  font-size: 12px !important;
  /*font-family: "poppins" !important;
  font-weight: bold !important;*/
  background-color: #f8f8f8 !important;
  letter-spacing: 1.2px !important;
  padding: 0rem !important;
}

.side-navigation-panel-select-inner-option-text {
  font-size: 11px !important;
  /*font-family: "poppins" !important;
  font-weight: bold !important;*/
  background-color: #f8f8f8 !important;
  /*letter-spacing: 1.2px !important;*/
}

.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option .side-navigation-panel-select-option-wrap .side-navigation-panel-select-option-text {
  font-size: 14px !important;
  /* font-family: "poppins" !important;
  font-weight: bold !important;
  letter-spacing: 1.2px !important;*/
  color: #333 !important;
}

.side-navigation-panel-select-option-selected {
  background: #f7f6f9 !important;
  font-size: 12px !important;
  border-left: 2px solid #42a5f5 !important;
}

.side-navigation-panel-select-option:hover {
  background: #f7f6f9 !important;
  font-size: 12px !important;
  border-left: 2px solid #42a5f5 !important;
}

.side-navigation-panel-select-option-icon-chevron-down {
  font-size: 10px !important;
}

.side-navigation-panel-select-option-selected::after {
  content: "";
  right: 0;
  position: absolute;
  font-size: 16px;
  /*font-weight: bold;
  font-family: "Poppins";*/
  color: #000;
}

.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option .side-navigation-panel-select-option-wrap .side-navigation-panel-select-option-text {
  font-weight: 900 !important;
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.username1 {
  font-weight: bolder;
  color: #05429f;
  letter-spacing: 1.2px;
  margin-top: -12px;
  font-size: 12px !important;
  line-height: 15px;
  padding-top: 5px;
}

.fileViewImg {
  height: 32px;
  width: 32px;
  object-fit: cover;
}

/*Landing Page*/
.topBar {
  background-color: #fff;
  position: fixed;
  top: 0%;
  height: 30vh;
}

.lgn .p-card-body {
  padding: 0 !important;
}

.lgn .p-card-content {
  padding: 0 !important;
}

.footer {
  background-color: #032970;
  position: fixed;
  bottom: 0%;
}

.wrapper {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(183, 194, 214, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#b7c2d6", GradientType=1);
}

.carousel .control-dots .dot {
  transition: opacity .25s ease-in;
  opacity: .3;
  filter: alpha(opacity=30);
  box-shadow: 1px 1px 2px rgb(0 0 0 / 90%);
  background: #032970 !important;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  cursor: pointer;
  display: inline-block;
  margin: 0 8px;
}

.carousel .control-dots .dot.selected {
  opacity: 1;
  filter: alpha(opacity=100);
  background: #032970 !important;
  padding: 5px !important;
}

.carousel .carousel-status {
  display: none !important;
}

.p-inputswitch:not(.p-disabled):hover .p-inputswitch-slider {
  background: #FFF !important;
}

.p-inputswitch .p-inputswitch-slider:before {
  background: #01fd1f !important;
  width: 1.25rem;
  height: 1.25rem;
  left: 0.25rem;
  margin-top: -0.625rem;
  border-radius: 50%;
  transition-duration: 0.2s;
}

.p-inputswitch .p-inputswitch-slider {
  background: #FFF !important;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  border-radius: 30px;
}

.en {
  vertical-align: middle;
  margin-top: -20px;
  position: absolute;
  top: 39px;
  right: 85px;
}

.np {
  vertical-align: middle;
  margin-top: -20px;
  position: absolute;
  top: 39px;
  right: 10px;
}


.p-inputswitch {
  position: relative;
  display: inline-block;

}

.p-inputswitchInnerPage {
  position: relative;
  display: inline-block;
  margin-right: 28px !important;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.p-col-1,
.p-col-2,
.p-col-3,
.p-col-4,
.p-col-5,
.p-col-6,
.p-col-7,
.p-col-8,
.p-col-9,
.p-col-10,
.p-col-11,
.p-col-12 {
  flex: 0 0 auto;
  padding: 0.2rem;
}

.p-column-title {
  font-size: 12px;
  /* font-family: 'poppins';*/
}

.card {
  /*font-family: 'poppins'!important;
  font-weight: 700;*/
}

.p-datatable .p-datatable-thead>tr>th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #FFF !important;
  background: #4e70ae !important;
  /* transition: box-shadow 0.2s; */
  color: #FFF;
}

.lgn {
  width: 80%;
  background: none !important;
  box-shadow: none !important;
}

.lgn .p-card-body {
  padding: 1rem;
  width: 88% !important;
}

.loginBtn {
  background-color: #0c6ab3 !important;
  color: #ffffff !important;
  border-color: #065ea7 !important;
}

.signature {
  position: relative;
  top: 0;
}

.HeadingTitle {
  color: #3f66ac !important;
  text-decoration: underline;
  font-size: 18px;
}

.ucTable {
  border-collapse: collapse;
  width: 100%;
}

.ucTable thead tr th {

  padding: 5px 15px;
  font-weight: 600;
  color: #FFF !important;
  background: #4e70ae !important;
  text-align: left;
  font-size: 14px;
}

.ucTable tbody tr td {
  padding: 5px 15px;
  color: #000 !important;
  background: #fff !important;
  font-size: 13px;
  text-align: left;
  /* transition: box-shadow 0.2s; */
}

.tbl-style thead tr th,
.tbl-style tbody tr td {
  padding: 10px;
}

.forgetPassword .p-card-body {

  width: 50% !important;

}


.side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option:hover {

  background-color: #ffebee !important;
  font-weight: 700 !important;
}

.officeName h4 {
  text-align: center;
  margin: 0;
  font-size: 1.4vw;
  color: #dd1a00;
}

.officeName h1 {
  text-align: center;
  font-size: 2.2vw;
  margin: 0px;
  font-weight: 600;
  color: #ad0007;
  text-shadow: 1px 1px 2px #8a8a8a;
}

/* .officeName h4 {
  text-align: center;
  margin: 0;
  font-size: 16px;
  color: #dd1a00;
}

.officeName h1 {
  text-align: center;
  font-size: 22px;
  margin: 0px;
  line-height: 32px;
  font-weight: 600;
  color: #ad0007;
  text-shadow: 1px 1px 2px #8a8a8a;
} */

.box-shadow {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.75);
}
/* .main-card { 
    background:#f7f6f9;
    padding: 30px;
    align-content: center;
    margin-top: 25px;
    border-radius: 16px;
     position: fixed;
    left: 0;
    height: 100vh;
    max-height:100%;
    box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2), 
    0 12px 17px 2px rgba(0, 0, 0, 0.14), 
    0 5px 22px 4px rgba(0, 0, 0, 0.12) !important;
    padding:30px;
} */

.main-card {
  padding: 15px;
  /* align-content: center; */
  margin-top: 15px !important;
  border-top-right-radius: 16px;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 60px;
  left: 0;
  /*min-width:261px;*/
}
.profileBox {
  background: rgb(255, 255, 255);
  padding: 15px;
  border-radius: 8px;
  margin-top: 15px;
  margin-bottom: 15px;
  border:1px solid #eae8ed;
}
.profileBox p {
  text-align: center;
  font-size: 15px;
  margin-bottom: 0px;
  /*font-family: 'poppins';
  font-weight: 700;*/
  line-height: 23px;
}
.logo1{
    height: 65px;
    padding-left: 22px;
    padding-top: 15px;
}

.p30 {
  padding: 30px !important;
}

.circleUser {
  width: 60px;
  height: 60px;
  padding: 15px;
  border-radius: 50%;
  background: #d00000;
  margin: 0% auto;
}
.circleUser .pi-user {
  text-align: center;
  margin: 0% auto;
  padding: 0px;
}
.sidebar-menu {
  padding-left: 0px !important;
}
.sidebar-menu li {
  list-style: none;
  text-align: left;
  width: 100%;
  background-color: #efefef;
  margin-bottom: 2px;
}
.sidebar-menu li a {
  text-decoration: none;
  display: block;
  text-align: left;
  color: #000;
  font-size: 12px;
  padding: 10px;
  border-left: 5px solid rgb(33, 150, 243);
  padding-left: 15px;
}

/* .p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 5px;
    border:none!important;
    color: #495057;
    background:none!important;
    font-weight: 600;
    font-size:12px;
    border-radius: 3px;
    transition: box-shadow 0.2s;
} */

/* .p-highlight{
background: rgb(112 173 255);
color: #fff!important;
}
.p-highlight .p-accordion-header-text{
    color: #fff;
} */

/* .pi-chevron-right:before {
    content: "\e901";
    font-size: 12px;
} */

.copyright {
  position: fixed;
  bottom: 10px;
  font-size: 70%;
  text-align: center;
  left: 14px;
}

/*.datascroller-demo .card .p-component {
  min-height: 270px;
}*/

.datascroller-demo .card{
/* max-height:275px!important; */
overflow-y: auto;
min-height: 51vh;
}

/* .pi-chevron-right:before {
  content: "" !important;
} */

.card::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.card::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

.card::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}


.side-navigation-panel ul li .side-navigation-panel-select-option svg{
  color:#a59f9f!important;
}
.side-navigation-panel ul li .side-navigation-panel-select-option .side-navigation-panel-select-option-icon  {
width:14px!important;
height:14px!important;

}

.side-navigation-panel-select-inner-option-wrap{
  align-items:center;
  display:flex; 
  padding-left:16px!important;
}
.side-navigation-panel-select-inner-option-text {
  padding-top:5px!important;
  padding-bottom:5px!important;
}
.side-navigation-panel-select-inner{
  padding-top:5px!important;
  padding-bottom:5px!important;
  background-color: #f8f8f8!important;
  
}

.signout-btn{
    padding: 5px 15px;
    border-radius: 15px;
    outline: none;
    border: 1px solid #ccc;
    text-align: center;
    font-size: 11px;
    font-family: 'Poppins';
    font-weight: bold;
    color: #000;
    float: none;
    margin:0px auto;
    letter-spacing: 1.2px;
    cursor: pointer;

}
.profileBox button{
  text-align: center;
  float: none;
  margin:0px auto;
}
.signout-btn i{
  font-size: 11px;
  vertical-align: middle;

}

/*#kycForm .p-col-11{
float:right!important;
}

#kycForm .main-body-card{
    width: 91.6667%;
    float: right;
}*/

.side-navigation-panel ul li .side-navigation-panel-select-option {
  padding-top:6px!important;
  padding-bottom:6px!important;
  padding-left:8px!important;
  border-left:2px solid #FFF!important;

}

.p-datatable-scrollable-header-box{
 margin-right:0px!important;
}
.datascroller-demo .name {
    font-size: 0.9rem;
    /*font-weight: 700;*/
    float: left;
}

.datascroller-demo .main-list {
    display: flex;
    align-items: center;
    padding: 1px;
    width: 100%;
}

.datascroller-demo .main-list .sub-list {
    flex: 1 1;
}

@media screen and (max-width: 576px) {   
    .datascroller-demo .main-list .sub-list {
        text-align: center;
    }
}
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.page {
  width: 103.3mm;
  height: 71.3mm;
  /* width: 110mm;
  height: 80mm;   */
  padding: 1mm;
  background: rgb(246, 244, 244);
  /* border-bottom:1px solid black; */
}
.smart-page {
  width: 100mm;
  height: 100mm; 
  padding: 1mm;
  background: rgb(246, 244, 244);
  /* border-bottom:1px solid black; */
}

@page {
  size: 103.3mm 71.3mm;
  /* size: 110mm 80mm;  */
}


@media print {
  body {
    width: 105mm;
    height:148mm; 
    -webkit-print-color-adjust: exact;
    -moz-print-color-adjust: exact;
    -ms-print-color-adjust: exact;
    print-color-adjust: exact;
  }
   .page {
     border-bottom:none;
     }
  
     body * {
      visibility: hidden;
    }
  
    .section-to-print,
    .section-to-print * {
      visibility: visible;
    }
  
    .section-to-print {
      position: absolute;
      left: 0;
      top: 0;
      margin-top: 20px;
    }
    .section-to-print-smart,
    .section-to-print-smart * {
      visibility: visible;
    }
  
    .section-to-print-smart {
      position: absolute;
      left: 100px;
      top: 100px;
      margin-top: 20px;
    } 
 
    @page {
      size: 148mm 105mm; 
    }
    
    #ignoreButton {
      display: none;
    }
  }  


.reports table {
  font-size: 16px;
  white-space: nowrap;
  border-spacing: 0;
  table-layout: fixed;
  border: 1px solid black;
}
.reports table tr,th,td {
  border: 1px solid black;
  padding: 5px;
  white-space: normal;
  /* width: 10%; */
}

.reports table thead th {
  text-align: center;
  background: #cfcccc;
}

.reports table td {
  text-align: right;
}



.main-indicator {
  text-align: left;
}

.sub-indicator {
  text-align: right;
}
table{
  width:100%;
}
@media print {
  body {
    -webkit-print-color-adjust: exact;
    -moz-print-color-adjust: exact;
    -ms-print-color-adjust: exact;
     print-color-adjust: exact;
  }
   .page {
     border-bottom:none;
     }

     body * {
      visibility: hidden;
    }
  
    .section-to-print,
    .section-to-print * {
      visibility: visible;
    }
  
    .section-to-print {
      position: absolute;
      left: 0;
      top: 0;
      margin-top: 20px;
    }
    table{
      width:800px;
    }
    .logotext{
      float: left;
      width:800px;
    }

    .logostyle{
      float:left!important;
      width:50px;
    }
  
    @page {
      size: 210mm 297mm; 
    }
}
